Vue.component("accounts", {
  data: function () {
     return {
       accounts: []
     }
  },
  methods: {
    load: function() {
      axios.get("/api/v1/accounts").then(res => {
        if (res.data.accounts !== undefined) {
          this.accounts = res.data.accounts;
        }
      })
    }
  },
  created: function() {
      this.load();
  },
  template: `
  <div>
    <div class="list-group">
      <button class=" list-group-item  list-group-item-action"  v-for="item in accounts">
         {{item.paysystem }} / {{item.currency}} - {{item.money}}
         <button>Create gamesession</button>
      </button>
    </div>
  </div>`
});


var vapp = new Vue({
  el: '#app',
  data: {
      loggedin: false,
      showlogin: false,
      username: ""
  },
  methods:{
      updateInfo: function() {
          axios.get("/api/v1/me").then((res) => {
            console.log(res);
            this.username = res.data.name;
            this.loggedin = true;
            this.showlogin = false;
          }).catch((reterror) => {
            console.log(reterror.response);
            if (reterror.response.status == 401) { //check if unathorized
              this.loggedin = false;
              this.showlogin = true;
            }
          })
      },
      logout: function() {
        axios.post("/api/v1/logout");
        this.updateInfo();
      }
  },
  created: function(){
      this.updateInfo();
  }
});







//
//
//
// $(function() {
//     check_web_session();
//     $('.sidebar-menu').tree()
//
//     if (localStorage.getItem('user_fullname') != undefined) {
//         $('.user-fullname').text(localStorage.getItem('user_fullname'));
//     }
//     if (localStorage.getItem('user_picture') != undefined) {
//         console.log(localStorage.getItem('user_picture'));
//         $('.user-picture').attr('src', localStorage.getItem('user_picture'));
//     }
//     if (localStorage.getItem('user_email') != undefined) {
//         $('.user-email').text(localStorage.getItem('user_email'));
//     }
//
//     // автоматичний заголовок на головному вікні
//     $('.treeview-menu').on('click', function() {
//       //console.log($(this).parent('.treeview').find("span").html());
//       $('.mainwindow-header').text($(this).parent('.treeview').find("span").text());
//     });
// });
//
//
//
// function user_logout()
// {
//     $.post('/api/logout', "{}");
// }
//
//
//
//
// function show_add_site() {
//     $('.form-control').val('');
//     $('.mainforms').hide();
//     $('.mainform-addsite').show();
// }
//
// function show_dashboard() {
//     $('.mainforms').hide();
//     $('.mainform-dashboard').show();
//     $('.mainwindow-header').text("Dashboard");
// }
//
//
// function show_sendgrid() {
//     $('.form-control').val('');
//     $('.mainforms').hide();
//     $('.mainform-sendgrid').show();
// }
//
//
// function settings_add_new_site(name, logo, url) {
//   var a = { 'name' : name , 'logo' : logo, 'url' : url};
//   $.post('/api/site/add', JSON.stringify(a))
//    .done(function(r) {
//      if (r.error == undefined) {
//          menu_sites_list.reload();
//          show_dashboard();
//      }
//   });
// }
//
//
// var menu_sites_list = new Vue({
//   el: '#menu-sites-list',
//   data: { sites : []  },
//   mounted: function () {
//     this.reload();
//   },
//   methods: {
//     sitedialog : function(event) {
//       if (event.target != undefined) {
//         show_site_dialog($(event.target).attr('siteid'));
//       }
//     },
//     reload : function() {
//         $.get("/api/site").done(function (r) {
//             console.log(this.sites);
//             console.log(r);
//
//             if (r.error == undefined) {
//                 this.sites = r.sites;
//             }
//         });
//     }
//   }
// });
//
//
//
// function show_site_dialog(siteid) {
//     $('.mainforms').hide();
//     $('.mainform-sitedialog').show();
//     $.each(menu_sites_list.sites, (i,v) => {
//       if (v.id == siteid) {
//         $.extend(site_dialog, v);
//       }
//     });
// };
//
//
// function show_paysystem_dialog(paysystemid) {
//     $('.mainforms').hide();
//     $('.mainform-paysystemdialog').show();
//     paysystem_dialog.id = paysystemid;
// };
//
//
// var site_dialog = new Vue({
//     el: '#mainform-sitedialog',
//     data: {
//       id: -1,
//       name: '',
//       url: '',
//       logo: ''
//     },
//     methods: {
//       reload: function() {
//         var a = { id : this.id };
//         $.post('/api/site/load', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 $.extend(this, r.site);
//             }
//         });
//       },
//       updatesite: function() {
//         var a = { id : this.id , name : this.name, url : this.url, logo : this.logo};
//         $.post('/api/site/update', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 menu_sites_list.reload();
//             }
//         });
//       },
//       deletesite: function() {
//         var a = { id : this.id };
//         $.post('/api/site/delete', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 menu_sites_list.reload();
//                 show_dashboard();
//             }
//         });
//       }
//     }
// });
//
//
//
// var menu_paysystems_list = new Vue({
//   el: '#menu-paysystems-list',
//   data: { paysystems : []  },
//   mounted: function () {
//     this.reload();
//   },
//   methods: {
//     reload: function() {
//       jpost('/api/paysystem', {}).then((r) => {
//         if (r.error == undefined) {
//           $.extend(this, r);
//         }
//       });
//     },
//     opendialog : function(event) {
//       if (event.target != undefined) {
//         show_paysystem_dialog($(event.target).attr('paysystemid'));
//       }
//     }
//   }
// });
//
//
// var paysystem_dialog = new Vue({
//     el: '#mainform-paysystemdialog',
//     data: {
//       id: '',
//       name: '',
//       enabled: false
//     },
//     methods: {
//       reload: function() {
//         var a = { id : this.id };
//         $.post('/api/site/load', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 $.extend(this, r.site);
//             }
//         });
//       }
//     }
// });
//
//
//
// var sendgrid_dialog = new Vue({
//     el: '#mainform-sendgrid',
//     data: {
//       name: '',
//       email: '',
//       apikey: ''
//     },
//     methods: {
//       reload: function() {
//         $.post('/api/v1/settings/sendgrid', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 $.extend(this, r.site);
//             }
//         });
//       },
//       update: function() {
//         var a = { id : this.id , name : this.name, url : this.url, logo : this.logo};
//         $.post('/api/site/update', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 menu_sites_list.reload();
//             }
//         });
//       },
//       deletesite: function() {
//         var a = { id : this.id };
//         $.post('/api/site/delete', JSON.stringify(a)).then((r) => {
//             if (r.error == undefined) {
//                 menu_sites_list.reload();
//                 show_dashboard();
//             }
//         });
//       }
//     }
// });
//
//
//